<template>
  <v-col cols="12" sm="6">
    <v-autocomplete
      density="compact"
      variant="outlined"
      :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.City')"
      :loading="personAddress.city.loading"
      :items="personAddress.city.items"
      v-model="person.city"
      @update:search="onCitySearch"
      :rules="[rules.required]"
      :data-test="`${isSender ? 'sender' : 'receiver'}-settlement`"
      :menu-props="{ contentClass: `${isSender ? 'sender' : 'receiver'}-settlement-items` }"
    />
  </v-col>
  <v-col cols="12" sm="6">
    <v-autocomplete
      density="compact"
      variant="outlined"
      :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.Warehouse')"
      :loading="personAddress.address.loading"
      :items="personAddress.address.items"
      v-model="person.address"
      :rules="[rules.required]"
      :data-test="`${isSender ? 'sender' : 'receiver'}-warehouse`"
    />
  </v-col>
</template>

<script setup>
import { defineProps, onMounted, reactive, watch } from "vue";
import i18n from "@/plugins/i18n";
import { requiredRule } from "@/services/field.rules";
import { novaPoshtaService } from "../services/novaposhta.service";

const props = defineProps({
  person: {},
  isSender: {
    type: Boolean,
    default: false,
  },
});

const personAddress = reactive({
  city: {
    loading: false,
    items: [],
    search: "",
  },
  address: {
    items: [],
    search: "",
  },
});

const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
};

onMounted(() => {
  populatePersonValues();
});

const populatePersonValues = async () => {
  if (props.person.city?.id) {
    personAddress.city.items = [
      {
        title: props.person.city.title,
        value: props.person.city,
      },
    ];
    await getWarehouses(props.person.city.id);

    props.person.city = personAddress.city.items.find((x) => x.value.id === props.person.city.id)?.value || props.person.city;
  }
  if (props.person.address?.id) {
    props.person.address = personAddress.address.items.find((x) => x.value?.id == props.person.address.id)?.value || props.person.address;
  }
};

var settlements = [];
const getWarehouses = async (settlementId) => {
  const warehouses = settlements.find((x) => x.id === settlementId)?.warehouses || [];

  const filtered = props.isSender ? warehouses.filter((x) => x.categoryOfWarehouse != "Postomat") : warehouses;
  var mapped = filtered
    .sort((a, b) => a.number - b.number)
    .map((x) => ({ title: x.title, value: { id: x.id, title: x.title, categoryOfWarehouse: x.categoryOfWarehouse } }));

  personAddress.address.items = mapped;
};

const searchSettlement = async (text) => {
  settlements = await novaPoshtaService.searchSettlements(text);

  return settlements.map((x) => ({ title: x.title, value: { title: x.title, id: x.id } }));
};

var cityTimeout = -1;
const onCitySearch = (val) => {
  if (personAddress.city.loading || !val || val === props.person.city?.title) return;

  if (cityTimeout !== -1) clearTimeout(cityTimeout);

  cityTimeout = setTimeout(async () => {
    personAddress.city.loading = true;
    try {
      props.person.address = null;

      personAddress.city.items = await searchSettlement(val);
    } catch (error) {
      console.log(error);
    } finally {
      personAddress.city.loading = false;
      clearTimeout(cityTimeout);
    }
  }, 1000);
};

watch(
  () => props.person.city?.id,
  (val) => {
    // if (accountChanged.value) {
    //   accountChanged.value = false;
    //   return;
    // }

    if (!val) return;

    getWarehouses(val);
  }
);
</script>
