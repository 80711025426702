<template>
  <v-row>
    <v-col v-if="isSender" cols="12" sm="6">
      <label>Обліковий запис</label>
      <v-select
        :items="accountOptions"
        :modelValue="selectedAccount"
        density="compact"
        variant="outlined"
        hide-details
        item-title="text"
        item-value="value"
        v-model:menu="selectMenu"
      >
        <template v-slot:selection="{ item }">
          <span class="d-flex flex-wrap gap8">
            <v-chip v-if="item.value.isBusiness" color="green" variant="tonal">Бізнес</v-chip>
            <v-chip v-else color="primary" variant="tonal">{{ item.value.description }}</v-chip>
            {{ item.title }}
          </span>
        </template>

        <template v-slot:item="{ item }">
          <v-list-item @click="onAccountSelected(item.value)">
            <span class="d-flex flex-wrap gap8 justify-center">
              {{ item.title }}
              <v-chip v-if="item.value.isBusiness" color="green" variant="tonal" density="compact">Бізнес</v-chip>
              <v-chip v-else color="primary" variant="tonal" density="compact">{{ item.value.description }}</v-chip>
            </span>
          </v-list-item>
        </template>
      </v-select>
    </v-col>

    <PersonInfo v-else :person="person" />
  </v-row>
  <v-row>
    <NovaPoshtaWarehouseSelector :person="person" :is-sender="isSender" />
  </v-row>
</template>

<script setup>
import { computed, defineProps, onMounted, watch, ref } from "vue";
import PersonInfo from "./PersonInfo.vue";
import NovaPoshtaWarehouseSelector from "./NovaPoshtaWarehouseSelector.vue";

const props = defineProps({
  person: {},
  isSender: {
    type: Boolean,
    default: false,
  },
  template: {},
  accounts: {},
});

const selectedAccount = ref(null);
const selectMenu = ref(false);

onMounted(() => {
  selectedAccount.value = accountOptions.value.find((x) => x.value.personId == props.person.id)?.value || accountOptions.value[0]?.value || null;
  onAccountSelected(selectedAccount.value);
});

const accountOptions = computed(() => (!props.accounts?.length ? [] : props.accounts.map((x) => ({ text: `${x.fullName} ${x.phone}`, value: x }))));

const onAccountSelected = (account) => {
  if (!account) return;

  props.person.id = account.personId;
  props.person.fullName = account.fullName;
  props.person.phoneNumber = account.phone;

  selectMenu.value = false;
};

watch(
  () => props.person.id,
  (val) => {
    if (!props.isSender || !val) return;

    selectedAccount.value = accountOptions.value.find((x) => x.value.personId == val).value;
  }
);

watch(
  () => props.template?.deliveryInfo.sender.id,
  () => {
    console.log(props.template?.deliveryInfo);
    if (props.template) {
      selectedAccount.value = accountOptions.value.find((x) => x.value.personId == props.template.deliveryInfo.sender.id)?.value || null;

      onAccountSelected(selectedAccount.value);
    }
  }
);
</script>
