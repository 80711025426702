<template>
  <v-col cols="12" sm="6">
    <label>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.ReceiverFullname") }}</label>

    <v-text-field
      v-model="person.fullName"
      density="compact"
      variant="outlined"
      :rules="[rules.required, rules.fullName]"
      placeholder="Введіть ПІБ отримувача"
    />
  </v-col>
  <v-col cols="12" sm="6" v-if="!isSender">
    <label>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.Phone") }}</label>
    <v-text-field
      density="compact"
      variant="outlined"
      :rules="[rules.required, rules.phoneNumber]"
      v-maska:[phoneNumberMaskOptions]
      placeholder="38"
      v-model="person.phoneNumber"
    />
  </v-col>
</template>

<script setup>
import { defineProps } from "vue";
import i18n from "@/plugins/i18n";
import { vMaska } from "maska";

import { phoneNumber, requiredRule } from "@/services/field.rules";

const phoneNumberMaskOptions = { mask: "38###-###-##-##" };

const props = defineProps({
  person: {},
});

const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
  phoneNumber: (value) => phoneNumber(value, i18n.global.t("Common.ErrorMessage.PhoneNumber")),
  fullName: (value) => /\S+\s+\S+/.test(value) || i18n.global.t("Common.ErrorMessage.FullName"),
};
</script>
