<template>
  <v-overlay :modelValue="overlay">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
  <v-card v-if="loading">
    <v-progress-linear indeterminate color="primary"></v-progress-linear>
  </v-card>
  <div v-else class="d-flex flex-column gap24 pa-8">
    <div class="d-flex align-center gap8">
      <Tooltiped :text="$t('Common.Label.Back')">
        <v-btn icon style="font-size: 24px" variant="text" @click="goBack">
          <v-icon large>custom:arrowLeftIcon</v-icon>
        </v-btn>
      </Tooltiped>
      <h1 class="h1">
        <span v-if="editMode">
          {{ $t("Orders.Label.OrderEdit") }} <span>#{{ order.orderNumber }}</span>
        </span>
        <span v-else>
          {{ $t("Orders.NewOrder.Label.Header") }}
        </span>
      </h1>
    </div>
    <div>
      <v-form ref="form" validate-on="submit" class="d-flex flex-column gap24">
        <v-card class="pa-6">
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-textarea density="compact" variant="outlined" v-model="order.note" auto-grow rows="1" :label="$t('Common.Label.Note')" />
            </v-col>
          </v-row>

          <v-alert border="start" type="info" color="rgba(200, 200, 200, 0.1)" border-color="primary" class="my-4" v-if="!editable">
            <template #prepend>
              <v-btn icon variant="tonal" color="#FFFFFF" class="op07">
                <v-icon color="primary" class="info primary">custom:warningIcon</v-icon>
              </v-btn>
            </template>
            <div class="d-flex flex-column gap8">
              <h4 class="subtitle2" style="color: #5a5858">Зверніть увагу - Замовлення не редагується!</h4>
              <span
                class="body1"
                style="color: #5a5858"
                v-html="
                  `Для цього замволення була сформована ТТН, тому якщо <b>компанія доставки ще не обробила </b> його, ви можете: <ol><li>Видалити ТТН;</li>Змінити замовлення;<li>Згенерувати нову ТТН.</li></ol>`
                "
              >
              </span>
            </div>
          </v-alert>
          <h3></h3>
        </v-card>

        <OrderGoodsComponent v-if="editable" v-model="order.orderLineItems" :touched="isFormSubmitted" />
        <GoodsTable v-else :config="{ headers: goodsTableHeaders }" :items="order.orderLineItems" />

        <!-- <v-card class="pa-6 d-flex flex-column gap24">
          <h4 class="title">{{ $t("Orders.NewOrder.Delivery.Header") }}</h4>
          <v-radio-group v-model="order.deliveryInfo.deliveryMethodId" inline color="primary" label="Метод доставки" hide-details>
            <v-radio v-for="deliveryMethod in deliveryMethods" :key="deliveryMethod.value" :label="deliveryMethod.text" :value="deliveryMethod.value"></v-radio>
          </v-radio-group>

          <div v-if="order.deliveryInfo.deliveryMethodId == 2" class="d-flex flex-column gap16">
            <div class="d-flex flex-column gap8">
              <h5 class="subtitle2 op05">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.ReceiverInfo") }}</h5>
              <v-row>
                <PersonInfo :person="order.deliveryInfo.receiver" />
              </v-row>
            </div>
          </div>
        </v-card> -->

        <!-- <v-card v-if="order.deliveryInfo.deliveryMethodId == 1" class="pa-6 d-flex flex-column gap24"> -->
        <v-card class="pa-6 d-flex flex-column gap24">
          <v-overlay :model-value="!isNovaPoshtaAvailable" contained class="d-flex justify-center align-center">
            <v-card class="d-flex flex-column gap16 pa-4 align-self-center" width="400px">
              <h1 class="h2">
                {{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.NotLinkedHeader") }}
              </h1>
              <p class="subtitle op07">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.NotLinkedDesc") }}</p>
              <v-btn color="primary" link :to="{ name: 'settings-integrations' }" target="_blank">{{ $t("Inbox.Button.LinkInstagram") }}</v-btn>
              <Tooltipped :text="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.NotLinkedRefresh')" :top="true">
                <div class="d-flex justify-center">
                  <v-btn @click="setNovaPoshta" variant="text" class="link"> Я вже підключи(в/ла) </v-btn>
                </div>
              </Tooltipped>
            </v-card>
          </v-overlay>

          <div class="d-flex flex-column gap8" v-if="!order.deliveryInfo.deliveryNumber">
            <h4 class="title">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Header") }}</h4>
            <div class="d-flex gap16 justify-space-between flex-wrap-reverse">
              <span class="subtitle op05" v-if="suggestToAddDeliveryNumber">Заповніть дані відправлення, щоб сформувати накладну</span>
              <NovaPoshtaAddTTN @ttn-data-fetched="onTTNDataUsed" />
            </div>
          </div>

          <v-alert v-if="order.deliveryInfo.printed" density="compact" border="start" type="warning" variant="outlined">
            <h4>{{ $t("Orders.Delivery.NovaPoshta.Label.PrintedHeader") }}</h4>
            <span>
              {{ $t("Orders.Delivery.NovaPoshta.Label.PrintedDesc") }}
            </span>
          </v-alert>

          <div v-if="editable" class="d-flex flex-column gap16">
            <div class="d-flex flex-column gap8">
              <v-row v-if="templateOptions.length && editable">
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="selectedTemplate"
                    variant="outlined"
                    density="compact"
                    :items="templateOptions"
                    :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.ChooseTemplate')"
                    item-title="text"
                    item-value="value"
                  />
                </v-col>
              </v-row>

              <h5 class="subtitle2 op05">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.SenderInfo") }}</h5>
              <NovaPoshtaPersonInfo is-sender :person="order.deliveryInfo.sender" :template="selectedTemplate" :accounts="accounts" />
            </div>

            <v-divider></v-divider>

            <div class="d-flex flex-column gap8">
              <h5 class="subtitle2 op05">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.ReceiverInfo") }}</h5>
              <NovaPoshtaPersonInfo :person="order.deliveryInfo.receiver" />
            </div>

            <v-divider></v-divider>

            <div class="d-flex flex-column gap8">
              <h5 class="subtitle2 op05">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.DeliveryInfo") }}</h5>
              <div>
                <v-row>
                  <v-col cols="12" sm="6" md="4" v-if="!showSeats">
                    <label>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.Weight") }}</label>
                    <v-text-field
                      v-model.number="order.deliveryInfo.weight"
                      density="compact"
                      variant="outlined"
                      type="number"
                      placeholder="Введіть вагу відправлення"
                      :rules="[rules.required, rules.greaterOrEqualThan]"
                    >
                      <template #append> {{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.KG") }}</template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <label>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.AnnouncementPrice") }}</label>
                    <v-text-field
                      v-model.number="order.deliveryInfo.cost"
                      density="compact"
                      variant="outlined"
                      type="number"
                      placeholder="Введіть оголошену вартість"
                      :rules="[rules.required, rules.greaterOrEqualThan]"
                    >
                      <template #append> {{ $t("Common.Label.Currency") }}</template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <OrderDescriptionComponent v-model="order.deliveryInfo.description" />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-checkbox inset hide-details v-model="infoRegClientBarcodes" color="primary">
                      <template #label>
                        {{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.InfoRegClientBarcodes") }}
                        <Tooltipped :text="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.InfoRegClientBarcodesInfo')">
                          <v-icon>custom:infoIcon</v-icon>
                        </Tooltipped>
                      </template>
                    </v-checkbox>

                    <v-checkbox inset hide-details v-model="additionalInformation" color="primary">
                      <template #label>
                        {{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.AdditionalInformation") }}
                        <Tooltipped :text="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.AdditionalInformationInfo')">
                          <v-icon>custom:infoIcon</v-icon>
                        </Tooltipped>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <NovaPoshtaSeat v-if="showSeats" :seats="order.deliveryInfo.seats" :deliveryId="order.deliveryInfo.id" />
                <v-row class="mt-4">
                  <v-col cols="12" sm="6" md="4">
                    <label>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.Payer") }}</label>
                    <v-select
                      density="compact"
                      variant="outlined"
                      :items="deliveryInfo.payerTypes"
                      v-model="order.deliveryInfo.payerType"
                      :rules="[rules.required]"
                      item-title="text"
                      item-value="value"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-switch
                      v-model="order.deliveryInfo.remittance.active"
                      :label="$t('Orders.NewOrder.Delivery.NovaPoshta.Label.Remittance')"
                      color="primary"
                      inset
                      hide-details
                    />
                  </v-col>
                  <v-col v-if="isBusinessAccountSelected" cols="12" sm="6" md="4" class="d-flex align-self-center">
                    <v-checkbox
                      color="primary"
                      label="Контроль оплати ? (зарахування коштів на розрахунковий рахунок)"
                      density="compact"
                      variant="outlined"
                      v-model="order.deliveryInfo.paymentControl"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12" v-if="order.deliveryInfo.remittance.active">
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <label>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.RemittanceSum") }}</label>
                        <v-text-field
                          variant="outlined"
                          density="compact"
                          type="number"
                          v-model="order.deliveryInfo.remittance.total"
                          placeholder="Введіть суму переказу"
                          :rules="[rules.required, rules.equalsToCost]"
                        >
                          <template #append> {{ $t("Common.Label.Currency") }}</template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <label>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.RemittanceDeliveryPayer") }}</label>
                        <v-select
                          density="compact"
                          variant="outlined"
                          :items="deliveryInfo.payerTypes.filter((x) => x.value != 'ThirdPerson')"
                          v-model="order.deliveryInfo.remittance.payer"
                          :rules="[rules.required]"
                          item-title="text"
                          item-value="value"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>

          <div v-else class="d-flex flex-column gap16">
            <h5 class="subtitle2 op05">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Header") }}</h5>

            <v-data-table class="body1" :headers="novaPoshtaTableHeaders" :items="[order.deliveryInfo]" hide-default-footer disable-sort disable-filtering>
              <template v-slot:[`item.id`]="{}">
                <NovaPoshtaToolbarActions
                  icon
                  :selected="[order]"
                  @data-updated="loadOrderDetails"
                  :selector="(o) => o.deliveryInfo.deliveryNumber"
                  @change-overlay="changeOverlay"
                />
              </template>
              <template v-slot:[`item.remittance.total`]="{ item }">
                {{ item.remittance.active ? item.remittance.total : "-" }}
              </template>
              <template v-slot:[`item.createdOn`]="{ item }">
                {{ moment.utc(item.createdOn).local().format("LLLL") }}
              </template>

              <template v-slot:[`item.statusTitle`]="{ item }">
                <Tooltiped :text="item.statusTitle" v-if="order.deliveryInfo.deliveryNumber">
                  <v-chip link style="max-width: 200px" :color="novaPoshtaStatusColors[item.statusId]">
                    <span
                      :class="{ 'font-weight-bold': item.statusId == 7 || item.statusId == 8 }"
                      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
                    >
                      {{ item.statusTitle }}
                    </span>
                  </v-chip>
                </Tooltiped>
              </template>
            </v-data-table>

            <v-row>
              <v-col class="d-flex flex-column gap16">
                <h5 class="subtitle2 op05">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.SenderInfo") }}</h5>

                <v-card class="pa-4 body1">
                  <p>{{ order.deliveryInfo.sender.fullName }} {{ order.deliveryInfo.sender.phoneNumber }}</p>
                  <p>{{ order.deliveryInfo.sender.city.title }}</p>
                  <p>
                    {{ order.deliveryInfo.sender.address.title }}
                  </p>
                </v-card>
              </v-col>
              <v-col class="d-flex flex-column gap16">
                <h5 class="subtitle2 op05">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.ReceiverInfo") }}</h5>

                <v-card class="pa-4 body1">
                  <p>{{ order.deliveryInfo.receiver.fullName }} {{ order.deliveryInfo.receiver.phoneNumber }}</p>
                  <p>{{ order.deliveryInfo.receiver.city.title }}</p>
                  <p>
                    {{ order.deliveryInfo.receiver.address.title }}
                  </p>
                </v-card>
              </v-col>
              <v-col class="d-flex flex-column gap16">
                <h5 class="subtitle2 op05">{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.DeliveryInfo") }}</h5>
                <v-card class="pa-4 body1">
                  <p>
                    <span>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.Description") }}: </span> {{ order.deliveryInfo.description }}
                  </p>
                  <p>
                    <span>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.AnnouncementPrice") }}: </span> {{ order.deliveryInfo.cost }}
                  </p>
                  <p>
                    <span>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.Payer") }}: </span>
                    {{ order.deliveryInfo.payerType == senderPayer ? order.deliveryInfo.sender.fullName : order.deliveryInfo.receiver.fullName }}
                  </p>
                  <p>
                    <span>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.DeliveryPayForm") }}: </span>
                    {{ paymentMethod[order.deliveryInfo.paymentMethod] }}
                  </p>
                </v-card>
              </v-col>
            </v-row>
            <div v-if="order.deliveryInfo.remittance.active" class="d-flex flex-column gap16">
              <h5 class="subtitle2 op05">{{ $t("Orders.Delivery.Label.AdditionalServices") }}</h5>
              <v-card class="pa-4 body1">
                <v-row>
                  <v-col>
                    <p>
                      <span>{{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.Remittance") }}: </span>
                      {{ order.deliveryInfo.remittance.total }}грн
                      <span class="text-lowercase">{{
                        deliveryInfo.remittanceReceiveForm.find((x) => x.value == order.deliveryInfo.remittance.recieveForm).text
                      }}</span>
                      . {{ $t("Orders.NewOrder.Delivery.NovaPoshta.Label.RemittanceDeliveryPayer") }}
                      {{ deliveryInfo.payerTypes.find((x) => x.value == order.deliveryInfo.remittance.payer).text }}
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div>
        </v-card>
      </v-form>
    </div>
    <div class="d-flex justify-center flex-wrap-reverse gap16">
      <v-btn width="200px" height="48px" variant="outlined" rounded @click="processCancel"> {{ $t("Common.Button.Cancel") }} </v-btn>
      <v-btn
        width="200px"
        height="48px"
        @click="processOrder"
        :disabled="!isSaveOrderBtnActive"
        :loading="saving"
        color="#2999FD"
        rounded
        variant="flat"
        style="color: white"
      >
        {{ editMode ? $t("Common.Button.Save") : $t("Common.Button.Create") }}</v-btn
      >
    </div>
  </div>
</template>

<script setup>
import Tooltipped from "@/components/Tooltiped.vue";
import { novaPoshtaService } from "../services/novaposhta.service";
import { greaterOrEqualThanRule, requiredRule } from "@/services/field.rules";
import { deliveryInfo } from "../models/novaposhta";
import { orderService } from "../services/order.service";
import PersonInfo from "../components/PersonInfo.vue";
import NovaPoshtaPersonInfo from "../components/NovaPoshtaPersonInfo.vue";
import NovaPoshtaAddTTN from "../components/NovaPoshtaAddTTN.vue";
import _ from "underscore";
import NovaPoshtaSeat from "../components/NovaPoshtaSeat.vue";
import OrderGoodsComponent from "../components/OrderGoodsComponent.vue";
import OrderDescriptionComponent from "../components/OrderDescriptionComponent.vue";
import { getCreateOrderVm, deliveryMethods } from "../models/create-order";
import i18n from "@/plugins/i18n";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { computed, nextTick, onMounted, watch, ref, defineProps, reactive } from "vue";
import { createPatch } from "rfc6902";
import Tooltiped from "@/components/Tooltiped.vue";
import GoodsTable from "@/modules/warehouse/components/GoodsTable.vue";
import NovaPoshtaToolbarActions from "./NovaPoshtaToolbarActions.vue";
import moment from "moment-timezone";
import { PayerType } from "../models/create-order";
import { goodsTableHeaders, novaPoshtaTableHeaders, novaPoshtaStatusColors } from "../views/order.configs";

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false,
  },
});
const store = useStore();
const router = useRouter();
const route = useRoute();

const order = ref(getCreateOrderVm());
const accounts = ref([]);
const loading = ref(true);

const rules = {
  required: (value) => requiredRule(value, i18n.global.t("Common.ErrorMessage.Required")),
  greaterOrEqualThan: (value) => greaterOrEqualThanRule(value, 0.1, i18n.global.t("Common.ErrorMessage.GreaterThanOrEqual", { value: 0.1 })),
  equalsToCost: (value) => value == order.value.deliveryInfo.cost || i18n.global.t("Orders.NewOrder.Delivery.NovaPoshta.Label.DeliveryCostNotEqualsRemittance"),
};

const isFormSubmitted = ref(false);
const isNovaPoshtaAvailable = ref(true);
const saving = ref(false);
const selectedTemplate = ref(null);
const templateOptions = ref([]);

const infoRegClientBarcodes = ref(false);
const additionalInformation = ref(false);
const orderTotal = computed(() => order.value.orderLineItems.reduce((prev, current) => prev + current.quantity * current.unitPrice, 0));
const isSaveOrderBtnActive = computed(() => isNovaPoshtaAvailable.value && !saving.value);
const showSeats = computed(() => order.value.deliveryInfo.receiver?.address?.categoryOfWarehouse == "Postomat");
const suggestToAddDeliveryNumber = computed(() => {
  // eslint-disable-next-line
  isDeliveryReplaced.value = true;
  return !props.editMode || !order.value.deliveryInfo.deliveryNumber;
});

const isBusinessAccountSelected = computed(() => accounts.value.find((a) => a.personId === order.value.deliveryInfo.sender.id)?.isBusiness);

var orderSnapshot = null;
const isDeliveryReplaced = ref(false);
const editable = computed(() => !props.editMode || order.value.orderStatusId <= 2);
const paymentMethod = reactive({});
const senderPayer = PayerType.Sender;

onMounted(async () => {
  try {
    if (props.editMode) {
      await initEditForm();
    } else {
      await setNovaPoshta();
    }
  } finally {
    loading.value = false;
  }
});

const initEditForm = async () => {
  try {
    var getOrderPromise = orderService.getOrderById(route.params.id);
    var getAccountsPromise = novaPoshtaService.getSavedDeliveryInfo();
    await Promise.all([getOrderPromise, getAccountsPromise]);

    order.value = await getOrderPromise;
    accounts.value = await getAccountsPromise;

    console.log(order.value.deliveryInfo.remittance.total);
    orderSnapshot = JSON.parse(JSON.stringify(order.value));

    infoRegClientBarcodes.value = !!order.value.deliveryInfo.infoRegClientBarcodes;
    additionalInformation.value = !!order.value.deliveryInfo.additionalInformation;
  } catch (requestError) {
    if (requestError.response.status === 404) router.push({ name: "orders" });
  } finally {
    loading.value = false;
  }

  rules.equalsToCost = (value) =>
    value == order.value.deliveryInfo.cost || i18n.global.t("Orders.NewOrder.Delivery.NovaPoshta.Label.DeliveryCostNotEqualsRemittance");
};

const form = ref(null);

const processOrder = async () => {
  props.editMode ? await saveOrder() : createOrder();
};

const saveOrder = async () => {
  const isValid = (await form.value.validate()).valid;
  isFormSubmitted.value = true;

  if (!isValid || order.value.orderLineItems.length < 1) {
    await nextTick();
    var firstError = form.value.querySelector(".v-input--error");
    if (firstError) window.scroll({ top: firstError.offsetTop + firstError.scrollHeight - 32, behavior: "smooth" });

    return;
  }

  order.value.deliveryInfo.receiver.phoneNumber = order.value.deliveryInfo.receiver.phoneNumber.replaceAll("-", "");

  const orderToUpdate = JSON.parse(JSON.stringify(order.value));

  var operations = createPatch(orderSnapshot, orderToUpdate);
  if (isDeliveryReplaced.value) {
    operations = operations.filter((x) => !x.path.startsWith("/deliveryInfo/id"));
  }
  if (operations.length === 0) {
    goBack();
    return;
  }
  try {
    saving.value = true;
    await orderService.update(order.value.id, operations);
    store.dispatch("addNotification", {
      icon: "custom:warningIcon",
      text: i18n.global.t("Orders.Label.OrderUpdated"),
      color: "primary",
      duration: 3500,
    });
    goBack();
  } catch {
    store.dispatch("addError");
  } finally {
    saving.value = false;
  }
};

const createOrder = async () => {
  const isValid = (await form.value.validate()).valid;
  isFormSubmitted.value = true;
  if (!isValid || order.value.orderLineItems.length < 1) {
    await nextTick();
    var firstError = form.value.querySelector(".v-input--error");
    if (firstError) window.scroll({ top: firstError.offsetTop + firstError.scrollHeight - 32, behavior: "smooth" });

    return;
  }

  saving.value = true;
  try {
    await orderService.createOrder(order.value);
    router.push({ name: "orders" });
  } catch {
    store.dispatch("addError");
  } finally {
    saving.value = false;
  }
};

const loadOrderDetails = async () => {
  try {
    order.value = await orderService.getOrderById(route.params.id);
  } catch (requestError) {
    if (requestError.response.status === 404) router.push({ name: "orders" });
  } finally {
    loading.value = false;
  }
};

const processCancel = () => {
  if (props.editMode) {
    goBack();
  } else {
    goToOrdersList();
  }
};

const goToOrdersList = () => {
  router.push({ name: "orders" });
};

const goBack = () => {
  router.go(-1);
};

const overlay = ref(false);
const changeOverlay = (value) => {
  overlay.value = value;
};

const setNovaPoshta = async () => {
  try {
    accounts.value = await novaPoshtaService.getSavedDeliveryInfo();

    const templates = _.flatten(accounts.value.map((x) => x.templates));
    templateOptions.value = templates.map((x) => {
      return {
        text: x.name,
        value: x,
      };
    });

    selectedTemplate.value = templates.find((x) => x.default) || templates[0];
    form.value?.resetValidation();
    isNovaPoshtaAvailable.value = true;

    infoRegClientBarcodes.value = !!selectedTemplate.value.deliveryInfo.infoRegClientBarcodes;
    additionalInformation.value = !!selectedTemplate.value.deliveryInfo.additionalInformation;
  } catch {
    isNovaPoshtaAvailable.value = false;
  }
};

const onTTNDataUsed = (deliveryInfo) => {
  if (suggestToAddDeliveryNumber.value) order.value.deliveryInfo = { ...deliveryInfo };
};

watch(infoRegClientBarcodes, (value) => {
  order.value.deliveryInfo.infoRegClientBarcodes = value ? "{{ order.OrderNumber }}" : "";
});

watch(additionalInformation, (value) => {
  order.value.deliveryInfo.additionalInformation = value ? "{{ for item in order.OrderItems }}{{item.Title}} ({{item.Quantity}});{{ end }}" : "";
});

watch(selectedTemplate, () => {
  if (!selectedTemplate.value) return;

  const receiver = order.value.deliveryInfo.receiver;
  order.value.deliveryInfo = { ...selectedTemplate.value.deliveryInfo, receiver };
  order.value.deliveryInfo.seats = [...order.value.deliveryInfo.seats];
  additionalInformation.value = !!order.value.deliveryInfo.additionalInformation;
  infoRegClientBarcodes.value = !!order.value.deliveryInfo.infoRegClientBarcodes;
});

watch(
  () => order.value.deliveryInfo.sender.id,
  (value) => {
    if (accounts.value.length && !accounts.value.find((a) => a.personId === value)?.isBusiness) {
      order.value.deliveryInfo.remittance.recieveForm = deliveryInfo.remittanceReceiveForm.filter((x) => x.value !== "Card")[0].value;
    }
  }
);

watch(
  () => order.value.deliveryInfo.cost,
  (val) => {
    if (!loading.value) order.value.deliveryInfo.remittance.total = val;
  }
);

watch(
  orderTotal,
  () => {
    if (!loading.value) order.value.deliveryInfo.cost = orderTotal.value;
  },
  { deep: true }
);
</script>

<style>
.good .v-input__prepend-outer,
.good .v-input__append-outer {
  padding: 0 !important;
  margin: 0 !important;
}

.good .v-input__prepend-outer .v-btn {
  border-radius: 10px 0px 0px 10px;
}

.good .v-input__append-outer .v-btn {
  border-radius: 0px 10px 10px 0px;
}

.good .v-input__prepend-outer .v-btn,
.good .v-input__append-outer .v-btn,
.good .v-input__control,
.good .v-input__slot {
  background-color: initial;
  height: 38px;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 5px 0px rgb(0 0 0 / 14%), 0px 1px 0px 0px rgb(0 0 0 / 12%);
}

.good .v-text-field__slot {
  text-align-last: center;
}
</style>
